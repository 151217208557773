export const ACTIVE_SURVEY_LIMIT = 'activeSurveyLimit';
export const FOLDER_FEATURE_ACTIVE = 'folderFeatureActive';
export const SURVEY_RESPONSE_CAPACITY = 'surveyResponseCapacity';
export const REMOVE_FEEDBACK_SENSE_LOGO = 'removeFeedbackLogo';
export const ADD_CUSTOM_LOGO = 'addCustomLogo';
export const SKIP_LOGIC_FEATURE = 'skipLogicFeature';
export const TEAM_SEATS = 'teamMemberSeats';
export const LOGO_DATA = 'logoData';
export const EXPORT_FEATURE = 'export-feature';
export const AI_TEXT_ANALYSIS = 'AI_TEXT_ANALYSIS';
export const WORD_CLOUD = 'WORD_CLOUD';
export const TEAM_ROLES = 'TEAM_ROLES'
export const TOTAL_CUSTOMER = 'TOTAL_CUSTOMER';
export const PRODUCT_USAGE_TRACKING = 'PRODUCT_USAGE_TRACKING';
export const COMPANY_TAG = 'COMPANY_TAG';
export const HEALTH_HISTORY = 'HEALTH_HISTORY';
export const PUBLISH_AUTOMATION_COUNT = 'PUBLISH_AUTOMATION_COUNT';