export const featureList = [
    {
        name : 'No of surveys',
        starter : 'Unlimited',
        plus : 'Unlimited',
        business : 'Unlimited',
    },
    {
        name : 'Simultaneous active surveys',
        starter : 'Unlimited',
        plus : 'Unlimited',
        business : 'Unlimited',
    },
    {
        name : 'Users',
        starter : '1',
        plus : '3',
        business : '10',
    },
    {
        name : 'Included monthly responses',
        starter : '100',
        plus : '1000',
        business : '10000',
    },
    {
        name : 'Workspace',
        starter : '1',
        plus : '1',
        business : '1',
    },
    {
        name : 'Email & Link Surveys',
        starter : 'yes',
        plus : 'yes',
        business : 'yes',
    },
    {
        name : 'Website and In-product Surveys',
        starter : 'yes',
        plus : 'yes',
        business : 'yes',
    },
    {
        name : 'NPS, CSAT, CES and 12 more question types',
        starter : 'yes',
        plus : 'yes',
        business : 'yes',
    },
    {
        name : 'Skip Logic',
        starter : 'yes',
        plus : 'yes',
        business : 'yes',
    },
    {
        name : 'Multiple endings',
        starter : 'yes',
        plus : 'yes',
        business : 'yes',
    },
    {
        name : 'Standard support',
        starter : 'yes',
        plus : 'yes',
        business : 'yes',
    },
    {
        name : 'Download data',
        starter : 'yes',
        plus : 'yes',
        business : 'yes',
    },
    {
        name : 'Completion rate',
        starter : 'yes',
        plus : 'yes',
        business : 'yes',
    },
    {
        name : 'Templates',
        starter : 'yes',
        plus : 'yes',
        business : 'yes',
    },
    {
        name : 'Email notification',
        starter : 'yes',
        plus : 'yes',
        business : 'yes',
    },
    {
        name : '48 hour SLA response time',
        starter : 'yes',
        plus : 'yes',
        business : 'yes',
    },
    {
        name : '24 Hour SLA response time',
        starter : 'no',
        plus : 'yes',
        business : 'yes',
    },
    {
        name : 'Custom data filters',
        starter : 'no',
        plus : 'yes',
        business : 'yes',
    },
    {
        name : 'Notify respondents automatically by email when they submit a response',
        starter : 'no',
        plus : 'yes',
        business : 'yes',
    },
    {
        name : 'Redirect respondents to a custom URL when they submit a response',
        starter : 'no',
        plus : 'yes',
        business : 'yes',
    },
    {
        name : 'Add company logo',
        starter : 'no',
        plus : 'no',
        business : 'yes',
    },
    {
        name : 'Brand removal',
        starter : 'no',
        plus : 'yes',
        business : 'yes',
    },
    {
        name : 'Custom thank you screen',
        starter : 'no',
        plus : 'yes',
        business : 'yes',
    },
    {
        name : 'Word cloud',
        starter : 'no',
        plus : 'no',
        business : 'yes',
    },
    {
        name : 'Redirect from ending screen',
        starter : 'no',
        plus : 'no',
        business : 'yes',
    },
    {
        name : 'Priority support',
        starter : 'no',
        plus : 'no',
        business : 'yes',
    },
    {
        name : 'Teammate roles',
        starter : 'no',
        plus : 'no',
        business : 'yes',
    },
    {
        name : 'AI Survey Analysis',
        starter : 'no',
        plus : 'yes',
        business : 'yes',
    },
    {
        name : 'Results export (CSV, XLS, PNG, PDF)',
        starter : 'yes',
        plus : 'yes',
        business : 'yes',
    },
    {
        name : 'Customer data filters',
        starter : 'yes',
        plus : 'yes',
        business : 'yes',
    },
    {
        name : 'Number of integrations',
        starter : 'no',
        plus : 'unlimited',
        business : 'unlimited',
    },
    {
        name : 'Responses capacity per survey',
        starter : '250',
        plus : '1500',
        business : '6000',
    },
    {
        name : 'Responses stored on active surveys',
        starter : '500',
        plus : '3000',
        business : '20000',
    },
]