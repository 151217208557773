import { Box, Button, TextField, Typography, styled } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FSLoader from '../Components/FSLoader';
import Notification from '../Utils/Notification';
import * as LayoutStyles from '../Styles/LayoutStyles'
import axios from 'axios';
import { acceptCleanInviteAPI, processInviteAPI } from '../Utils/Endpoints';
import { modalButtonContainerStyle } from '../Styles/ModalStyle';
import { containedButton, outlinedButton } from '../Styles/ButtonStyle';
import { textFieldStyle } from '../Styles/InputStyles';
import { colorPalette } from '../Utils/Constants';

const CssTextField = styled(TextField)(textFieldStyle);

function ProcessInvite() {

    let init = { status: false };
    const [password, setPassword] = useState('');
    const defaultColor = useSelector((state: any) => state.colorReducer);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get("code");

    const snackbarRef: any = useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [inviteResponse, setInviteResponse] = useState<number>(0);
    const [inviteMessage, setInviteMessage] = useState<string>('');

    useEffect(() => {
        if (init.status === true) { return; }
        validateInviteCode();
        init.status = true;
    }, []);

    const validateInviteCode = async () => {
        try {
            setLoading(true);
            const { data } = await axios.get(processInviteAPI(code as string), { withCredentials: true });
            setInviteResponse(data?.statusCode);
            setInviteMessage(data?.message);
            setLoading(false);
        } catch (error: any) {
            setInviteResponse(error?.response?.data?.statusCode);
            setInviteMessage(error?.response?.data?.message);
            setLoading(false);
        }
    }

    const GetInviteResponseUI = () => {
        return (
            <>
                {inviteResponse === 0 && <Box></Box>}
                {inviteResponse === 200 && <SuccessDisplay
                    defaultColor={defaultColor}
                    next={(password :string,name :string) => handleAcceptInvite(false,password,name)}
                    reject={handleReject}
                    message={inviteMessage}
                />}
                {inviteResponse === 500 && <Box>
                    <Typography variant='h6' >
                        {inviteMessage}
                    </Typography>
                </Box>}
                {inviteResponse === 409 && <ConfirmationDisplay
                    defaultColor={defaultColor}
                    next={(password :string,name :string) => handleAcceptInvite(true,password,name)}
                    reject={handleReject}
                />}
            </>
        )
    }

    const handleAcceptInvite = async (
        deleteUser: boolean,
        password :string,
        name :string
    ) => {
        try {
            setLoading(true);
            const payload = {
                deleteUser : deleteUser,
                password : password,
                name :name
            }

            if(password == null || password.length < 8){
                snackbarRef?.current?.show('Please provide a valid password', 'error');
                return;
            }


            if(name == null || name.length < 1){
                snackbarRef?.current?.show('Please provide your name', 'error');
                return;
            }

            const { data } = await axios.post(
                acceptCleanInviteAPI(code as string),
                payload,
                { withCredentials: true });
            snackbarRef?.current?.show(data?.message, 'success');
            setTimeout(() => {
                navigate('/login')
            }, 1000);
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            snackbarRef?.current?.show(error?.response?.data?.message, 'error');
            setLoading(false);
        }
    }

    const handleReject = () => {
        window.close();
    }

    return (
        <>
            <Box
                sx={{ backgroundColor: defaultColor?.backgroundColor, color: colorPalette.darkBackground, textAlign: 'center' }}
                height={'calc(100vh - 57px)'}
            >
                <Box padding={'10%'}>
                    {GetInviteResponseUI()}
                </Box>
            </Box>
            <FSLoader show={loading} />
            <Notification ref={snackbarRef} />
        </>
    )
}

export default ProcessInvite

function SuccessDisplay({ defaultColor, next, reject, message }: any) {

    const [name, setName] = useState('');
    const [password, setPassword] = useState('');

    return (
        <Box sx={{ ...LayoutStyles.globalSettingSubContainers(colorPalette.textSecondary), marginTop: 0, textAlign: 'start' }} >
            <Typography
                color={'black'}
                variant='h6'
            >{message}</Typography>
            <Typography
                color={colorPalette.primary}
            >
                Click the button below to join
            </Typography>
            <Box marginTop={'10px'} >
                <CssTextField
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    size='small'
                    label='Name'
                    fullWidth
                    sx={{ marginTop: '10px', width: '300px' }}
                />
            </Box>
            <Box marginTop={'10px'} >
                <CssTextField
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    size='small'
                    label='Password'
                    type='password'
                    fullWidth
                    sx={{ marginTop: '10px', width: '300px' }}
                />
                <Typography sx={{ fontSize: '12px', color: 'gray',marginLeft : '5px' }} >
                    Password should be at least 8 characters
                </Typography>
            </Box>
            <Box sx={{ textAlign: 'start', marginTop: '10px' }} >
                <Button
                    style={{ width: 'fit-content', marginRight: '15px' }}
                    sx={containedButton}
                    variant="contained"
                    onClick={() => next(password,name)}
                    disabled={password.length < 8}
                >
                    Join
                </Button>
                <Button
                    style={{ width: 'fit-content' }}
                    sx={{ ...outlinedButton, backgroundColor: defaultColor?.primaryColor }}
                    variant="contained"
                    onClick={reject}
                >
                    Reject
                </Button>
            </Box>
        </Box>
    )
}

function ConfirmationDisplay({ defaultColor, next, reject }: any) {

    const [validVal, setValidVal] = useState('');
    const [proceed, setProceed] = useState(false);
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');

    useMemo(() => {
        if (validVal === 'continue') {
            setProceed(true);
        } else {
            setProceed(false);
        }
    }, [validVal]);

    return (
        <Box sx={{ ...LayoutStyles.globalSettingSubContainers(colorPalette.textSecondary), marginTop: 0, textAlign: 'start' }} >
            <Typography
                variant='h6'
                color={'black'}
            >You are about to join a team, Removing resources</Typography>
            <Typography
                color={colorPalette.primary}
            >
                By joining a new team, you will loose all of your current data
            </Typography>
            <CssTextField
                sx={{ marginTop: '10px' }}
                id="outlined-basic"
                placeholder='Type "continue" to proceed'
                variant="outlined"
                size={'small'}
                style={{ width: '100%' }}
                value={validVal}
                onChange={(e) => setValidVal(e.target.value)}
            />
            <Box marginTop={'10px'} >
                <CssTextField
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    size='small'
                    label='Name'
                    fullWidth
                    sx={{ marginTop: '10px', width: '300px' }}
                />
            </Box>
            <Box marginTop={'10px'} >
                <CssTextField
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    size='small'
                    label='Password'
                    type='password'
                    fullWidth
                    sx={{ marginTop: '10px', width: '300px' }}
                />
                <Typography sx={{ fontSize: '12px', color: 'gray',marginLeft : '5px' }} >
                    Password should be at least 8 characters
                </Typography>
            </Box>
            <Box sx={{ textAlign: 'start', marginTop: '10px' }} >
                <Button
                    style={{ width: 'fit-content', marginRight: '15px' }}
                    sx={containedButton}
                    variant="contained"
                    disabled={!proceed || password.length < 8}
                    onClick={() => next(password,name)}
                >
                    Accept
                </Button>
                <Button
                    style={{ width: 'fit-content' }}
                    sx={{ ...outlinedButton, backgroundColor: defaultColor?.primaryColor }}
                    variant="contained"
                    onClick={reject}
                >
                    Reject
                </Button>
            </Box>
        </Box>
    )
}