export const DEFAULT_KEY = 'default'

export const CONDITION_HAS_ANY_VALUE = 'answer has any value';
export const CONDITION_ANSWER_CONTAINS = 'answer contains';
export const CONDITION_IS = 'is';
export const CONDITION_IS_EXACTLY = 'is exactly';
export const CONDITION_IS_NOT = 'is not';
export const CONDITION_ANY_VALUE = 'has any value';
//unused
export const CONDITION_QUESTION_IS_ANSWERED = 'question is answered';
export const CONDITION_INCLUDES_ALL = 'includes all of the following';
export const CONDITION_QUESTION_IS_NOT_ANSWERED = 'question is not answered';

//smiley scale names
export const SMILEY_EXTREMELY_UNSATISFIED = 'Extremely Unsatisfied';
export const SMILEY_UNSATISFIED = 'Unsatisfied';
export const SMILEY_NEUTRAL = 'Neutral';
export const SMILEY_HAPPY = 'Happy';
export const SMILEY_EXTREMELY_HAPPY = 'Extremely Happy';
    