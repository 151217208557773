
export const useCases = [
    {
        id: 1,
        heading: 'Proactively Address Customer Health',
        description: 'Assess and monitor the health score of your customers using configurable metrics. Identify at-risk clients early and take proactive steps to prevent churn'
    },
    {
        id: 2,
        heading: 'Organize and Track Key Contacts',
        description: 'Manage individual contact information for each client company, tracking roles and responsibilities to facilitate targeted communication and support'
    },
    {
        id: 3,
        heading: 'Stay on Top of Client Contracts',
        description: 'Track the status of contracts with your clients to ensure timely renewals and avoid lapses. Automate reminders for account managers to initiate renewal discussions'
    },
    {
        id: 4,
        heading: 'Navigate Every Stage of the Customer Journey',
        description: 'Monitor and manage the customer journey from onboarding to advocacy, launching targeted engagement campaigns at each stage to enhance customer satisfaction'
    },
    {
        id: 5,
        heading: 'Ensure Successful Onboarding and Mitigate Risks',
        description: 'Track the progress of new customers through the onboarding process and identify potential risks to reduce churn and enhance customer retention'
    },
    {
        id: 6,
        heading: 'Gain Insights from Product Usage Data',
        description: 'Track how frequently your customers use your product to identify power users and those needing more support. Develop engagement strategies based on usage patterns'
    },
    {
        id: 7,
        heading: 'Monitor Key User Events',
        description: 'Track and analyze key events in your product to understand user behavior, identify opportunities for improvement, and drive feature adoption'
    },
    {
        id: 8,
        heading: 'Streamline Task Assignment and Tracking',
        description: 'Assign and track tasks related to customer management, ensuring timely follow-ups and actions. Facilitate collaboration among team members by tracking responsibilities'
    },
    {
        id: 10,
        heading: 'Maintain Detailed Customer Interaction Records',
        description: 'Keep comprehensive notes on customer interactions and history to ensure continuity in support and relationship management. Share insights across your team for better coordination'
    },
    {
        id: 11,
        heading: 'Collect and Analyze Customer Feedback',
        description: 'Deploy surveys such as NPS and CSAT to gather valuable feedback from customers. Analyze responses to gauge satisfaction and identify areas for improvement'
    },
    {
        id: 12,
        heading: 'Understand Customer Sentiment',
        description: 'Analyze survey responses to understand customer sentiment and address concerns promptly. Extract actionable insights to drive customer satisfaction initiatives'
    },
    {
        id: 13,
        heading: 'Make Informed Strategic Decisions',
        description: 'Track key performance metrics like health scores, at-risk companies, churn rate, retention rate, and quarterly renewals. Use this data to make informed strategic decisions about customer management and product development'
    },
    {
        id: 14,
        heading: 'Automate Repetitive Tasks for Efficiency',
        description: 'Use our automation workflows to streamline processes such as updating records, sending emails, and assigning tasks based on specific triggers. Improve operational efficiency by automating routine tasks'
    },
    {
        id: 15,
        heading: 'Workflows with Advanced Components',
        description: 'Incorporate advanced components like “Wait for”, “Create New Task”, “Send Email”, “Send Survey”, “Assign User”, and “Update Record” to build sophisticated automation workflows tailored to your business needs'
    }
]