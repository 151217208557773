import { Box, Typography } from '@mui/material'
import React from 'react'
import { colorPalette } from '../../Utils/Constants'

function EmptyAnalysis() {
  return (
    <Box color={colorPalette.darkBackground} marginTop={'10%'}>
            <Box>
                <img 
                  style={{width : '250px',margin : 'auto'}} 
                  alt='There are no results'
                  src='/EmptyAnalysis.png'
                ></img>
            </Box>
            <Typography variant='h6'>There are no results</Typography>
            <Typography color={'#808080'} >You have not collected any responses yet</Typography>
            <Typography color={'#808080'} >Any response your receive will appear here!</Typography>
        </Box>
  )
}

export default EmptyAnalysis