import React, { useEffect, useState } from 'react'
import { logicType } from '../../Utils/types';
import { modalLogicStyle } from '../../Styles/ModalStyle';
import { Autocomplete, Box, Chip, Divider, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material';
import { muiSelectStyle, textFieldStyle } from '../../Styles/InputStyles';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { v4 as uuidv4 } from "uuid";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import styled from '@emotion/styled';
import { colorPalette, dateAnswerOperators, multipleAnswerOperators, singleAnswerOperators, textAnswerOperators } from '../../Utils/Constants';
import { answerNotNeededSet, parseDataType } from '../../Utils/FeedbackUtils';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import InfoIcon from '@mui/icons-material/Info';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { SKIP_LOGIC_FEATURE } from '../../Utils/CustomSettingsConst';
import UpgradePlanError from '../UpgradePlanError';


const logicSelectContainer = {
    padding: '20px',
    marginTop: '20px',
    borderRadius: '6px',
    backgroundColor: colorPalette.textSecondary,
    boxShadow: 'rgba(0, 0, 0, 0.08) 0px 2px 4px'
}

const logicListContainer = (bgColor : string) => {
    return {
        padding: '10px',
        marginTop: '20px',
        borderRadius: '6px',
        backgroundColor: bgColor,
        cursor: 'pointer',
        boxShadow: 'rgba(0, 0, 0, 0.08) 0px 2px 4px'
    }
}

const logicIconsContainer = (bgColor : string) => {
    return {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor : bgColor,
        padding: '5px',
        marginBottom: '10px',
        borderRadius: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.08) 0px 2px 4px'
    }
}

const CssTextField = styled(TextField)(textFieldStyle);

type propType = {
    type: number,
    values: string[] | number[],
    data: logicType[],
    open?: boolean
}

function CreateLogic(props: propType, ref: any) {

    const settings = useSelector((state: any) => state.settings);

    const [logicList, setLogicList] = useState<logicType[]>([]);
    const [operators, setOperators] = useState<string[]>([]);

    //display variables
    const [showAnswerList, setShowAnswerList] = useState(false);
    const [showAutoComplete, setShowAutoComplete] = useState(false);
    const [visible,setVisible] = useState(false);

    useEffect(() => {
        handlePlanVisibility();
        populateOperator();
        populateData();
        populateDisplayFlags();
    }, [props.data]);

    const handlePlanVisibility = () => {
        if (settings != null && parseDataType(settings[SKIP_LOGIC_FEATURE]) === true) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }

    const populateDisplayFlags = () => {
        setShowAnswerList(false);
        setShowAutoComplete(false);
        if (props?.values?.length > 0) {
            setShowAnswerList(true);
        }

        if (props.type === 5) {
            setShowAutoComplete(true);
        }

    }

    const populateData = () => {
        if (props.data != null && props.data?.length > 0) {
            setLogicList(props.data);
        } else {
            setLogicList([]);
        }
    }

    const populateOperator = () => {
        if (props.type === 3 || props.type === 7 || props.type === 6 || props.type === 8 || props.type === 9) {
            setOperators(singleAnswerOperators);
        } else if (props.type === 4) {
            setOperators(multipleAnswerOperators);
        } else if (props.type === 5) {
            setOperators(textAnswerOperators);
        } else if (props.type === 13) {
            setOperators(dateAnswerOperators);
        }
    }

    const handleAddNewLogic = () => {
        const tempLogicType = [...logicList];
        tempLogicType.push({
            id: uuidv4(),
            operator: '',
            path: '',
            value: '',
            showValue: true
        });
        setLogicList(tempLogicType);
    }

    const handleRemoveLogic = (logicId: string) => {
        const tempLogicType: logicType[] = [];
        logicList.forEach(logic => {
            if (logic.id !== logicId) {
                tempLogicType.push(logic);
            }
        });
        setLogicList(tempLogicType);
    }

    const fetchData = () => {
        return logicList;
    }

    React.useImperativeHandle(ref, () => ({
        fetchData: fetchData
    }));

    const handleOperatorChange = (e: any, logicId: string) => {
        const value = e.target.value;
        const tempLogics: logicType[] = JSON.parse(JSON.stringify(logicList));
        tempLogics.forEach(logic => {
            if (logic.id === logicId) {
                logic.operator = value;
            }
            if (answerNotNeededSet.has(logic.operator)) {
                logic.showValue = false;
                if (logic.id === logicId) {
                    logic.value = '';
                }
            } else {
                if (logic.id === logicId) {
                    logic.value = '';
                }
                logic.showValue = true;
            }
        });
        setLogicList(tempLogics);
    }

    const handleValueChange = (e: any, logicId: string) => {
        const value = e.target.value;
        const tempLogics: logicType[] = JSON.parse(JSON.stringify(logicList));
        tempLogics.forEach(logic => {
            if (logic.id === logicId) {
                logic.value = value;
                return;
            }
        });
        setLogicList(tempLogics);
    }

    const handlePathNameChange = (e: any, logicId: string) => {
        const value = e.target.value;
        const tempLogics: logicType[] = JSON.parse(JSON.stringify(logicList));
        tempLogics.forEach(logic => {
            if (logic.id === logicId) {
                logic.path = value;
                return;
            }
        });
        setLogicList(tempLogics);
    }

    const handleAutoCompleteSave = (e: any, logicId: string) => {
        const value = e.target.value;
        const tempLogics: logicType[] = JSON.parse(JSON.stringify(logicList));
        tempLogics.forEach(logic => {
            if (logic.id === logicId) {
                if(logic.value.length > 0){
                    logic.value += `,${value}`;
                }else{
                    logic.value += `${value}`;
                }
                return;
            }
        });
        setLogicList(tempLogics);
    }

    const handleChipDelete = (deletedValue: string, logicId: string) => {
        const tempLogics: logicType[] = JSON.parse(JSON.stringify(logicList));
        tempLogics.forEach(logic => {
            if (logic.id === logicId) {
                const chipData = logic.value.split(',');
                const tempChip: string[] = [];
                chipData.forEach(chip => {
                    if (chip !== deletedValue) {
                        tempChip.push(chip);
                    }
                });
                logic.value = tempChip.join(',');
            }
        });
        setLogicList(tempLogics);
    }

    const moveItem = (fromIndex: number, toIndex: number) => {
        const updatedList = [...logicList];
        const [movedItem] = updatedList.splice(fromIndex, 1);
        updatedList.splice(toIndex, 0, movedItem);
        setLogicList(updatedList);
    };

    return (
        <>
            {
                visible && props.open === true &&
                <Box sx={modalLogicStyle} >
                    <Typography>Add logic</Typography>
                    <Typography
                        marginBottom={'10px'}
                        color={'#808080'}
                    >Wondering how survey logic works?
                        <span
                            style={{ marginTop: '10px', textDecoration: 'underline', cursor: 'pointer', marginLeft: '10px' }} >
                            Learn more here.
                        </span>
                    </Typography>
                    <Divider />
                    <DndProvider backend={HTML5Backend}>
                        {
                            logicList?.map((logic, index) => {
                                return (
                                    <DraggableLogicItem
                                        key={logic.id}
                                        logic={logic}
                                        index={index}
                                        moveLogic={moveItem}
                                        handleRemoveLogic={handleRemoveLogic}
                                        handleOperatorChange={handleOperatorChange}
                                        operators={operators}
                                        showAnswerList={showAnswerList}
                                        handleValueChange={handleValueChange}
                                        values={props.values}
                                        showAutoComplete={showAutoComplete}
                                        handleAutoCompleteSave={handleAutoCompleteSave}
                                        handleChipDelete={handleChipDelete}
                                        handlePathNameChange={handlePathNameChange}
                                    />
                                )
                            })
                        }
                    </DndProvider>
                    <Box sx={logicSelectContainer}>
                        <Typography
                            onClick={handleAddNewLogic}
                            sx={{ cursor: 'pointer', textAlign: 'center' }} color={colorPalette.primary}
                        >+ Add new logic</Typography>
                    </Box>
                </Box>
            }
            {
                !visible && props.open === true && 
                <Box textAlign={'center'} >
                    <UpgradePlanError
                        message='Upgrade plan to use survey conditions'
                        desc='This feature is available in "Company" & "Enterprise" plans'
                        showButton={false}
                    />
                </Box>

            }
        </>
    )
}

export default React.forwardRef(CreateLogic)

function DraggableLogicItem({ logic, index, ...otherProps }: any) {
    
    const [, refDrag] = useDrag({
        type: 'LOGIC_ITEM',
        item: { index },
    });

    const [, refDrop] = useDrop({
        accept: 'LOGIC_ITEM',
        hover: (draggedItem: any) => {
            if (draggedItem.index !== index) {
                otherProps.moveLogic(draggedItem.index, index);
                draggedItem.index = index;
            }
        }
    });

    const combinedRef = (node: any) => refDrag(refDrop(node));

    return (
        <Box ref={combinedRef} key={logic.id} >
            <Box sx={logicListContainer(colorPalette.background)} >
                <Box sx={logicIconsContainer(colorPalette.secondary)} >
                    <Box textAlign={'end'} >
                        <IconButton sx={{cursor : 'grab'}} >
                            <DragIndicatorIcon sx={{ color: colorPalette.darkBackground }} />
                        </IconButton>
                    </Box>
                    <Box textAlign={'end'} >
                        <IconButton
                            onClick={() => otherProps.handleRemoveLogic(logic.id)}
                        ><RemoveCircleIcon sx={{ color: colorPalette.darkBackground }} />
                        </IconButton>
                    </Box>
                </Box>
                <Box marginBottom={'20px'} justifyContent={'space-between'} display={'flex'} >
                    <Typography marginTop={'7px'} marginRight={'10px'} >If answer</Typography>
                    <Select
                        sx={muiSelectStyle}
                        style={{ width: '70%' }}
                        size='small'
                        value={logic.operator}
                        onChange={(e) => otherProps.handleOperatorChange(e, logic.id)}
                    >
                        {
                            otherProps.operators?.map((operator: any) => {
                                return (
                                    <MenuItem
                                        key={operator}
                                        value={operator}
                                    >{operator}
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </Box>
                {
                    otherProps.showAnswerList && logic.showValue &&
                    <Box marginBottom={'20px'} textAlign={'end'} >
                        <Select
                            sx={muiSelectStyle}
                            style={{ width: '70%', textAlign: 'start' }}
                            size='small'
                            value={logic.value}
                            onChange={(e) => otherProps.handleValueChange(e, logic.id)}
                        >
                            {
                                otherProps.values?.map((value: any, index: number) => {
                                    return (
                                        <MenuItem
                                            key={index}
                                            value={value}
                                        >{value}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </Box>
                }
                {
                    otherProps.showAutoComplete && logic.showValue &&
                    <Box marginBottom={'20px'} sx={{ textAlign: '-webkit-right' }}>
                        <Autocomplete
                            style={{ width: '70%' }}
                            multiple
                            id="tags-filled"
                            options={[]}
                            value={logic.value?.length > 0 ? logic.value?.split(',') : ''}
                            freeSolo
                            onChange={(e) => otherProps.handleAutoCompleteSave(e, logic.id)}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        variant="outlined"
                                        label={option}
                                        {...getTagProps({ index })}
                                        onDelete={(e) => otherProps.handleChipDelete(option, logic.id)}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <CssTextField
                                    {...params}
                                    size='small'
                                    label="Create options"
                                    placeholder="Tags"
                                />
                            )}
                        />
                        <Typography 
                            textAlign={'start'} 
                            width={'70%'} 
                            marginTop={'5px'} 
                            fontSize={'12px'} 
                            color={'#808080'}
                        >
                            Tip: After typing your tag, press 'Enter' to register it.
                        </Typography>
                    </Box>
                }
                <Box justifyContent={'space-between'} display={'flex'} >
                    <Typography marginTop={'7px'} marginRight={'10px'} >Go to</Typography>
                    <CssTextField
                        // sx={{ input: { color: 'white' } }}
                        id="outlined-basic"
                        placeholder='Path Name'
                        variant="outlined"
                        value={logic.path}
                        onChange={(e) => otherProps.handlePathNameChange(e, logic.id)}
                        size={'small'}
                        style={{ width: '70%' }}
                    />
                </Box>
            </Box>
        </Box>
    );
}