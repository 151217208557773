export const industryOptions = [
    { label: 'Technology', value: 'Technology' },
    { label: 'Healthcare', value: 'Healthcare' },
    { label: 'Finance', value: 'Finance' },
    { label: 'Manufacturing', value: 'Manufacturing' },
    { label: 'Retail', value: 'Retail' },
    { label: 'Education', value: 'Education' },
    { label: 'Transportation', value: 'Transportation' },
    { label: 'Real Estate', value: 'Real Estate' },
    { label: 'Hospitality', value: 'Hospitality' },
    { label: 'Energy', value: 'Energy' },
    { label: 'Government', value: 'Government' },
    { label: 'Non-Profit', value: 'Non-Profit' },
    { label: 'Agriculture', value: 'Agriculture' },
    { label: 'Entertainment', value: 'Entertainment' },
    { label: 'Food & Beverage', value: 'Food & Beverage' },
    { label: 'Professional Services', value: 'Professional Services' },
    { label: 'Construction', value: 'Construction' },
    { label: 'Telecommunications', value: 'Telecommunications' },
    { label: 'Media', value: 'Media' },
    { label: 'Pharmaceuticals', value: 'Pharmaceuticals' },
];


export const lifecycleStageOptions = [
    {label : 'Onboarding', value : 'Onboarding'},
    {label : 'Active', value : 'Active'},
    {label : 'Engaged', value : 'Engaged'},
    {label : 'At Risk', value : 'At Risk'},
    {label : 'Churned', value : 'Churned'},
    {label : 'Expansion', value : 'Expansion'},
    {label : 'Renewal', value : 'Renewal'},
    {label : 'Advocate', value : 'Advocate'},
];

export const companyStatusOptions = [
    {label : 'Active', value : 'Active'},
    {label : 'Inactive', value : 'Inactive'},
    {label : 'Verified', value : 'Verified'},
    {label : 'Unverified', value : 'Unverified'},
    {label : 'Compliant', value : 'Compliant'},
    {label : 'Non-Compliant', value : 'Non-Compliant'},
    {label : 'Good Standing', value : 'Good Standing'},
    {label : 'Delinquent', value : 'Delinquent'},
];

export const communicationPrefOptions = ['Email','Phone']

export const taskPriorityOptions = ['Low','Medium','High','Urgent'];

export const taskStatusOptions = ['Open' , 'InProgress' , 'Completed' , 'Cancelled'];

export const noteVisibilityOptions = ['private' , 'team' , 'public' ];